.canvas-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  /*border: 2px solid red;*/
}
.block-pipeline-canvas {
  background-color: white;
  border: 1px solid rgb(209, 213, 219); /* this is border-gray-300 equivalent */
  /* border-top-left-radius: 0.5rem;  this is rounded-t-lg equivalent */
  /* border-top-right-radius: 0.5rem; this is rounded-t-lg equivalent */
  cursor: pointer;
}
#dashboard.dragover {
  /* border-color: green;
  background-color: #e0f0e0;
  transform: scale(1.02);
  box-shadow: 0 0 15px rgba(0, 255, 0, 0.5); */
  cursor: copy;
}
.search-container {
  display: none;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
#suggestions {
  padding: 0px;
  position: absolute;
  max-height: 150px;
  overflow-y: auto;
  border: 1px solid #ccc;
  display: none;
  background-color: #fff;
  z-index: 10; /* Ensure suggestions appear on top */
  margin-top: 2px; /* Adjusted gap between input and suggestions */
  box-sizing: border-box; /* Include padding/border in the width calculation */
}
#suggestions li {
  padding: 0;
  margin: 0;
  padding-left: 5px;
  list-style-type: none;
  cursor: pointer;
  border-bottom: 1px solid #ccc; /* Only bottom border */
}
#suggestions li:last-child {
  border-bottom: none; /* Remove bottom border from the last item */
}
#suggestions li.active {
  background-color: #f0f0f0;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px;
  margin: 0px;
  padding-left: 10px;
  margin-top: -5px;
}
