#ui_slider {
  opacity: 0.8;
}
#ui_slider > div {
  pointer-events: none;
}
#ui_slider input,
#ui_slider label,
#ui_slider select,
#ui_slider option,
#ui_slider canvas {
  pointer-events: auto;
}
#ui_slider #rotation > canvas {
  background-color: rgba(255, 255, 255, 0.6);
}
#ui_slider {
  width: 200px;
}
@media (prefers-color-scheme: dark) {
  #ui_slider .ui_slider-dark-support {
    color: white;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
  }
}
